<template>
  <div>
    <page-title
      :heading="$t('generic.lang_terminals')"
      :subheading="$t('generic.lang_edit_terminal')"
      :icon="icon"
      show-previous-button
      url-previous="/kiosk/terminals"
    ></page-title>

    <div class="app-main__inner">
      <edit-terminal-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import EditTerminalComponent from "@/components/kiosk/kioskManagement/EditTerminalComponent";

export default {
  name: "EditTerminalView",

  components: {
    EditTerminalComponent,
    PageTitle,
  },

  data() {
    return {
      icon: "pe-7s-display2 icon-gradient bg-tempting-azure",
    };
  },
};
</script>
